/* *
* アニメーション
*/
const animateArry = document.getElementsByClassName('is-animate')
let scrollY;

function animationShow() {
  for (let i = 0; i < animateArry.length; i++) {
    if (animateArry[i].getAttribute('class').match(/is-show/) === null) {
      scrollY = window.pageYOffset + window.innerHeight
      clientTop = animateArry[i].getBoundingClientRect().top + window.pageYOffset

      if (clientTop < scrollY) {
        animateArry[i].setAttribute('class', animateArry[i].getAttribute('class') + ' is-show')
      }
    }
  }
}

// 初期で読み込み
animationShow()

//スクロールで要素読み込み
window.addEventListener('scroll', () => {
  animationShow()
})
